import { Box, useMediaQuery, useTheme } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { useEffect, useRef } from "react";
import { l_projects } from "../../locales";
import ProjectsTypography from "../../theme/ProjectsTypography";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const Title = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  gsap.registerPlugin(ScrollTrigger);

  const animationsRef = useRef<Tween[]>([]);

  useEffect(() => {
    animationsRef.current.forEach((anim) => {
      anim.scrollTrigger?.kill();
      anim.kill();
    });

    if (isOverMd) {
      animationsRef.current[0] = gsap.to(".projects-title-blue-lemon", {
        scrollTrigger: {
          trigger: ".projects-title-blue-lemon",
          scrub: 2,
          start: "top 20%",
          end: "+=200",
        },
        rotate: 210,
        y: -50,
        x: -70,
      });
      animationsRef.current[1] = gsap.to(".projects-title-orange-circle", {
        scrollTrigger: {
          trigger: ".projects-title-blue-lemon",
          scrub: 2,
          start: "top 20%",
          end: "+=200",
        },
        y: -80,
        x: -70,
      });
      animationsRef.current[2] = gsap.to(".projects-title-orange-pill", {
        scrollTrigger: {
          trigger: ".projects-title-blue-lemon",
          scrub: 2,
          start: "top 20%",
          end: "+=200",
        },
        x: -100,
      });
    }
  }, [isOverMd]);

  return (
    <Box
      sx={{
        width: "90%",
        margin: "auto",
        maxWidth: "1800px",
        marginTop: { md: "250px", xs: "100px" },
        marginBottom: { md: "200px", xs: "40px" },
        position: "relative",
        // backgroundColor: "yellow",
      }}
    >
      <ProjectsTypography
        variant="overtitle"
        sx={{ marginBottom: { md: "56px", xs: "16px" } }}
      >
        {l_projects("title.overtitle")}
      </ProjectsTypography>
      <ProjectsTypography
        variant="bigtitle"
        sx={{
          marginBottom: { md: "56px", xs: "16px" },
          width: "fit-content",
          // width: { md: "80%", xs: "100%" },
        }}
      >
        {l_projects("title.title1")}
        <br />
        {l_projects("title.title2")}
      </ProjectsTypography>
      <ProjectsTypography
        variant="body"
        sx={{
          color: "rgba(0, 0, 0, 0.6)",
          maxWidth: { xl: "850px", lg: "700px", md: "630px", xs: "500px" },
        }}
      >
        {l_projects("title.subtitle")}
      </ProjectsTypography>

      <Box
        sx={{
          position: "absolute",
          width: {
            xl: "230px",
            lg: "180px",
            md: "130px",
            sm: "80px",
            xs: "50px",
          },
          top: { md: "unset", xs: "-20px" },
          bottom: { xs: "unset", md: "10px" },
          right: {
            lg: "calc(18% - 100px)",
            md: "calc(10% - 100px)",
            xs: "10%",
          },
        }}
        className="projects-title-orange-pill"
      >
        <StaticImage alt="" src="../../images/orange-pill.svg" width={230} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: {
            xl: "190px",
            lg: "160px",
            md: "100px",
            sm: "50px",
            xs: "40px",
          },
          transform: "rotate(137deg)",
          top: { md: "0px", xs: "20px" },
          right: { lg: "12%", md: "5%", xs: "-10px" },
        }}
        className="projects-title-blue-lemon"
      >
        <StaticImage alt="" src="../../images/blue-lemon.svg" width={200} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          display: { sm: "block", xs: "none" },
          width: {
            xl: "105px",
            lg: "80px",
            md: "60px",
            sm: "40px",
          },
          transform: "rotate(137deg)",
          top: "40%",
          right: "0px",
        }}
        className="projects-title-orange-circle"
      >
        <StaticImage alt="" src="../../images/orange-circle.svg" width={105} />
      </Box>
    </Box>
  );
};

export default Title;
