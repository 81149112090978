/** @jsxImportSource @emotion/react */

import Footer from "../../components/shared/Footer/Footer";
import CaseStudy from "./components/CaseStudy/CaseStudy";
import Title from "./components/Title/Title";

const Projects = () => {
  return (
    <>
      <Title />
      <CaseStudy />
      <Footer />
    </>
  );
};

export default Projects;
