import { HeadProps } from "gatsby";
import SEOHead, { initHeadLanguage } from "../components/old/seo_head";
import Projects from "../features/projects_page";
import { l_projects } from "../features/projects_page/locales";

export default Projects;

export const Head = (headProps: HeadProps) => {
  initHeadLanguage(headProps);

  return (
    <SEOHead
      title={l_projects("seo.title")}
      description={l_projects("seo.description")}
      headProps={headProps}
    />
  );
};
