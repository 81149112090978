import { Typography, TypographyProps } from "@mui/material";
import { forwardRef } from "react";

type ProjectsTypographyProps = {
  variant?: "bigtitle" | "overtitle" | "body" | "title" | "span";
} & Omit<TypographyProps, "variant" | "color">;

const ProjectsTypography = forwardRef<any, ProjectsTypographyProps>(
  ({ variant, sx, ...props }, ref) => {
    return (
      <Typography
        ref={ref}
        component={
          variant === "span"
            ? "span"
            : variant === "bigtitle"
            ? "h1"
            : variant === "title"
            ? "h6"
            : variant === "overtitle"
            ? "h4"
            : "p"
        }
        sx={{
          color: "#000",
          ...(variant === "bigtitle"
            ? {
                fontSize: {
                  xl: "96px",
                  lg: "80px",
                  md: "64px",
                  sm: "40px",
                  xs: "36px",
                },
                lineHeight: {
                  xl: "100px",
                  lg: "84px",
                  md: "68px",
                  sm: "44px",
                  xs: "40px",
                },
                fontWeight: 700,
              }
            : variant === "overtitle"
            ? {
                fontSize: { md: "21px", xs: "16px" },
                lineHeight: { md: "21px", xs: "20px" },
                fontWeight: 700,
                textTransform: "uppercase",
              }
            : variant === "body"
            ? {
                fontSize: { md: "24px", xs: "16px" },
                lineHeight: { md: "32px", xs: "20px" },
                fontWeight: 400,
              }
            : variant === "title"
            ? {
                fontSize: { lg: "36px", md: "32px", xs: "16px" },
                lineHeight: { lg: "40px", md: "36px", xs: "20px" },
                fontWeight: 700,
              }
            : variant === "span"
            ? {
                fontSize: "inherit",
                lineHeight: "inherit",
                fontWeight: "inherit",
              }
            : {}),
          ...sx,
        }}
        {...props}
      />
    );
  }
);
export default ProjectsTypography;
