import { Box, Typography } from "@mui/material";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLayoutEffect } from "react";
import ButtonWithUnderline from "../../../../components/shared/ButtonWithUnderline";
import { l_projects } from "../../locales";
import ButtonRoundArrowIcon from "../../theme/ButtonRoundArrowIcon";
import ProjectsTypography from "../../theme/ProjectsTypography";
import { CaseStudyItem } from "./CaseStudy";

const CaseStudy = ({
  caseStudy,
  index,
}: {
  caseStudy: CaseStudyItem;
  index: number;
}) => {
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    let ctx = gsap.context((self) => {
      gsap.to(`.${caseStudy.name}`, {
        scrollTrigger: {
          trigger: `.${caseStudy.name}`,
          scrub: 2,
          start: "top bottom",
          end: "bottom top",
        },
        backgroundPosition: "50% 100%",
      });
    });
    return () => {
      ctx.revert();
    };
  }, []);

  return (
    <Box
      sx={{
        maxWidth: "720px",
        marginBottom: "240px",
        transform: `translateY(${index % 2 !== 0 ? "400px" : 0})`,
      }}
    >
      <Box
        sx={{
          width: "100%",
          aspectRatio: "720 / 624",
          backgroundImage: `url(${caseStudy.imgSrc})`,
          backgroundSize: "120% 120%",
          backgroundPosition: "50% 0%",
          borderRadius: "50% 50% 30px 30px",
          marginBottom: "40px",
          position: "relative",
        }}
        className={caseStudy.name}
      ></Box>
      <Typography
        sx={{
          fontSize: { md: "28px", lg: "32px" },
          letterSpacing: "0.23px",
          lineHeight: { md: "40px", lg: "44px" },
          fontWeight: 700,
        }}
      >
        {caseStudy.description}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: {
            md: "center",
            lg: caseStudy.name === "bonusx" ? "center" : "flex-end",
          },
          marginTop: "40px",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            height:
              caseStudy.name === "isatra"
                ? "45px"
                : caseStudy.name === "belvillage"
                ? "45px"
                : "56px",
            aspectRatio: String(caseStudy.logo.width / caseStudy.logo.height),
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundImage: `url(${caseStudy.logo.url})`,
          }}
        ></Box>
        <Box sx={{ flexShrink: 0 }}>
          <ButtonWithUnderline link={caseStudy.link}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { md: "6px", lg: "8px" },
              }}
            >
              <ProjectsTypography
                variant="body"
                sx={{ fontSize: { md: "18px", lg: "24px" } }}
              >
                {l_projects("casestudy.buttonText")}
              </ProjectsTypography>
              <ButtonRoundArrowIcon />
            </Box>
          </ButtonWithUnderline>
        </Box>
      </Box>
    </Box>
  );
};

const BigCaseStudies = ({ caseStudies }: { caseStudies: CaseStudyItem[] }) => {
  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: "1800px",
        margin: "auto",
        marginBottom: "150px",
        display: "grid",
        gridTemplateColumns: "minmax(0px, 720px) minmax(0px, 720px)",
        justifyContent: "space-between",
        gridColumnGap: "80px",
      }}
    >
      {caseStudies.map((cs, i) => (
        <CaseStudy caseStudy={cs} key={`big${cs.name}`} index={i} />
      ))}
    </Box>
  );
};

export default BigCaseStudies;
